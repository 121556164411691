<template>
  <b-container class="mb-5">
    <b-row>
      <b-col>
        <b>Project Status Filter:</b>
        <b-form-select
          v-model="status"
          class="m-4"
          style="width: 300px"
          :options="statuses"
        />
        <!-- <span>
        </span> -->
      </b-col>
      <b-col>
        <b>Closed reason filter:</b>
        <b-form-select
          v-model="closedReason"
          class="m-4"
          style="width: 300px"
          :options="closedReasonOptions"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b>Counter:</b> {{ filteredProjects.length }}
      </b-col>
    </b-row>
    <b-card
      v-for="project in filteredProjects"
      :key="project.id"
      class="m-1"
    >
      <b-row>
        <b-col
          class="m-1 d-inline-flex"
        >
          <b-col class="m-1">
            <p class="mb-0">
              <b> Client:</b> {{ project.business.name }}
            </p>
            <p class="mb-0">
              <b>Project Title:</b> {{ project.title }}
            </p>
            <p class="mb-0">
              <b>Preferred Candidate Location:</b> {{ project.preferred_locations || 'No info' }}
            </p>
          </b-col>
          <b-col cols="5">
            <p class="mb-0">
              <b>Project Status:</b> {{ project.status }}
            </p>
          </b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-btn
            class="ml-3"
            variant="success"
            :to="`/admin/project/${project.id}/edit`"
          >
            Edit project
          </b-btn>
        </b-col>
        <b-col>
          <b-btn
            v-if="['admin', 'manager'].includes($store.state.auth.user.role)"
            class="float-right p-1"
            @click="deleteProject(project.id)"
          >
            <img
              src="/images/delete24px.png"
              alt="del-btn"
            >
          </b-btn>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import { CLOSING_REASONS, PROJECT_STATUSES, statusToArray } from '@/helpers/statuses'

export default {
  name: 'ProjectsList',
  data () {
    return {
      projects: [],
      status: null,
      closedReason: null,
      statuses: [
        {
          value: null,
          text: 'All',
        },
        ...statusToArray(PROJECT_STATUSES),
      ],
      closedReasonOptions: [
        {
          value: null,
          text: 'All',
        },
        ...statusToArray(CLOSING_REASONS),
      ],
    }
  },
  computed: {
    filteredProjects () {
      let projects = this.projects
      if (this.status) {
        projects = projects.filter(r => r.status === this.status)
      }
      if (this.status === 'Closed' && this.closedReason) {
        projects = projects.filter(p => p.closed_reason === this.closedReason)
      }
      return projects
    },
  },
  created () {
    this.getProjects()
  },
  methods: {
    getProjects () {
      axios.get('/admin/v2/projects')
        .then(({ data }) => {
          this.projects = data
        })
    },
    deleteProject (projectId) {
      const confirmed = confirm('U are about to delete a project, sure you want to do it?')
      if (confirmed) {
        axios.delete(`/admin/v2/projects/${projectId}`)
          .then(() => {
            this.getProjects()
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
